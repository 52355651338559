export const dataProcess = [
  {
    itemDesc:
      "El proyecto se comenzó con el brief otorgado por el cliente, en el cual se detallaba el objetivo comunicacional de cada una de las ilustraciones e infografías que se querían realizar y algunas referencias de estilo. Con base en esto se definió un estilo lineal y plano, con una paleta de colores reducida, siendo uno de ellos el color naranja de la marca. Para cada una de las infografías se hicieron imágenes vectoriales que mantenían la misma esencia, y complementaban la comunicación de un fragmento del guión que sería usado a posterior en video presentaciones.",
  },
]

export const dataQuote = [
  {
    Text:
      "No es secreto que el mundo real dode el diseñador se desempeña, no es el mundo del arte, pero si el mundo de comprar y vender.",
    Autor: "Paul Rand",
  },
]
